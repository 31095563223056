import { MSPaneContainer } from "../../ms_front_lib/app/MSPaneContainer";
import { MSDropDown } from "../../ms_front_lib/components/MSDropDown";
import { CeDematSidebar } from '../ui/cedematSidebar';
import Mustache from "mustache";

import "./PageMain.css";
import page_content from "./PageMain.html";

export class PageMain extends MSPaneContainer {
  $create(iQuery) {
    // Crée le contenu HTML de la page principale en utilisant Mustache pour le rendu
    let html = gettext_localize(page_content);
    html = Mustache.render(html, (id) => {
      console.warn("mustache:" + id);
      return id;
    });
    const $res = $(html);
    this.$m_pane_container = $res.find("#pane_container");
    return $res;
  }
  // Appelé après la création de la page pour initialiser divers éléments de l'interface
  onAfterCreate(iQuery) {
    this.updateUserMenu();
    this.initSidebar();
    this.cloneNavigationToOffcanvas();
    this.setupResponsiveLogo();

    // ajouter la classe 'uglyNabar' si db_stage n'est pas 'prod'
    const token = gl_app.authToken();
    if (token && token.db_stage !== 'prod') {
      $('#navbar').addClass('uglyNabar');
    }
  }
  // Met à jour le menu utilisateur en fonction des informations de l'utilisateur
  updateUserMenu() {
    const token = gl_app.authToken();;
    console.log('====================================');
    console.log(token);
    console.log('====================================');
    if (!token) {
      return;
    }
    // Affiche le nom de l'utilisateur ou l'email s'il n'y a pas de nom complet
    if (token.user.first_name && token.user.last_name) {
      $('#user_name').text(token.user.last_name + ' ' + token.user.first_name);
    } else {
      $('#user_name').text(token.user.email);
    }
    $('#user_group').text(token.current_group_name);
    // Crée le menu déroulant pour les options utilisateur
    const items = [];
    if (token.shortcut_groups && token.shortcut_groups.length) {
      token.shortcut_groups.forEach((group) => {
        items.push({
          text: group.name,
          href: '##renew_auth?group=' + group.id,
        });
      });
      items.push('');
    }
    items.push({
      text: _l('Mon compte'),
      href: '#account',
    });
    items.push('');
    if (gl_app.stage != 'prod') {
      items.push({
        text: _l('Authentication Token'),
        href: '#auth_token',
      });
    }
    items.push({
      icon: "logout",
      text: 'Se déconnecter',
      href: '##logout',
    });
    // Crée et insère le menu utilisateur
    $('#user_menu_drop_down').remove();
    MSDropDown.create({
      id: 'user_menu_drop_down',
      items: items
    }).appendTo($('#user_menu'));
    // Applique des styles CSS au menu déroulant
    $('#user_menu_drop_down').addClass('drop_menu');
    $('.dropdown-item').addClass('text-bolder drop_hov_item')
  }
  // Initialise la barre latérale de navigation avec les éléments de menu triés
  initSidebar() {
    console.log(`initSidebar`);

    // Trie les éléments de la barre latérale par index
    gl_app.m_side_bar_items.sort((a, b) => {
      if (a.index < b.index) {
        return -1;
      }
      if (a.index > b.index) {
        return 1;
      }
      return 0;
    });
    // Crée la barre latérale avec les éléments triés
    this.m_side_bar = new CeDematSidebar();
    this.$m_sidebar = this.m_side_bar.$create({
      class: 'sidebar',
      items: gl_app.m_side_bar_items,
    });
    this.$m_sidebar.appendTo($('.container_nav'));
  }
  // Clone la barre de navigation principale pour l'afficher dans le menu off-canvas sur mobile
  cloneNavigationToOffcanvas() {
    const $navClone = $('.container_nav').clone();
    $navClone.removeClass('container_nav').addClass('container_nav_clone d-block d-md-none');
    $('.offcanvas-body').append($navClone);
  }
  // Configure le comportement du logo en fonction de la taille de l'écran
  setupResponsiveLogo() {
    const logo = document.querySelector('.header_logo');
    const updateLogoBehavior = () => {
      if (window.innerWidth >= 768) {
        logo.style.pointerEvents = 'none';
      } else {
        logo.style.pointerEvents = 'auto';
      }
    };

    updateLogoBehavior();
    window.addEventListener('resize', updateLogoBehavior);
  }
}
